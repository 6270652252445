import { tns } from './tiny-slider.js';
import VanillaScrollspy from './vanillajs-scrollspy.js';

const slider = tns({
  container: '.how-it-works__slider',
  items: 1,
  slideBy: 'page',
  controls: false,
  autoplay: true,
  autoplayButtonOutput: false,
  navPosition: 'bottom',
});

const slider2 = tns({
  container: '.white-papers__slider',
  items: 1,
  slideBy: 1,
  mouseDrag: true,
  swipeAngle: false,
  loop: false,
  controlsContainer: '#customize-controls',
});

const menu = document.querySelector('#vertical-nav');
const scrollspy = VanillaScrollspy({ menu });
scrollspy.init();

window.addEventListener('DOMContentLoaded', function () {
  function updateHeight() {
    if (window.innerWidth > 992) {
      document.querySelector('.jumbotron__movie').style.height = '';
      document.querySelector('.jumbotron__movie').style.left = '';
      return;
    }

    const topHeight = document.querySelector('.jumbotron__top').offsetHeight;
    document.querySelector('.jumbotron__movie').style.height = topHeight + 'px';
    const movieWidth = document.querySelector('.jumbotron__movie').offsetWidth;
    const containerWidth = document.querySelector(
      '.jumbotron__movie__container'
    ).offsetWidth;
    const difference = (movieWidth - containerWidth) / 2;
    document.querySelector('.jumbotron__movie').style.left = -difference + 'px';
  }

  window.addEventListener('resize', updateHeight);
  updateHeight();
});
